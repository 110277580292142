import React, { useState, useRef } from "react";
import {
  Box,
  Container,
  Stack,
  SimpleGrid,
  Text,
  Link,
  VisuallyHidden,
  chakra,
  useColorModeValue,
  Input,
  IconButton,
  FormControl,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { FaTwitter, FaYoutube, FaFacebook, FaTiktok } from "react-icons/fa";
import { BiMailSend } from "react-icons/bi";

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2} color="white">
      {children}
    </Text>
  );
};

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue("whiteAlpha.200", "whiteAlpha.200")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("whiteAlpha.300", "whiteAlpha.300"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer() {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const formRef = useRef(null);

  const scriptURL = process.env.REACT_APP_GOOGLE_SHEET_URL;

  const handleSubscribe = async (e) => {
    e.preventDefault();
    if (email) {
      setIsSubmitting(true);
      const formData = new URLSearchParams();
      formData.append("email", email);

      try {
        const response = await fetch(scriptURL, {
          method: "POST",
          body: formData,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });

        if (response.ok) {
          const responseText = await response.text();
          console.log("Response text:", responseText);

          toast({
            title: "Subscribed!",
            description: "Thank you for subscribing to our newsletter!",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          setEmail(""); // Clear the input after successful subscription
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error!", error.message);
        toast({
          title: "Error",
          description: "There was an error subscribing. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast({
        title: "Error",
        description: "Please enter a valid email address.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      bg={useColorModeValue("gray.900", "gray.900")}
      color={useColorModeValue("gray.200", "gray.200")}
    >
      <Container as={Stack} maxW={"6xl"} py={10}>
        <SimpleGrid
          templateColumns={{ sm: "1fr 1fr", md: "2fr 1fr 1fr 2fr" }}
          spacing={8}
        >
          <Stack spacing={6}>
            <Box>
              <Text fontSize={"2xl"} fontWeight={"bold"} color="white">
                Maggie Safari Ventures
              </Text>
              <Text mt={2} color="gray.300">
                Discover the beauty of Africa with our expertly crafted safari
                experiences. We bring you closer to nature, wildlife, and
                unforgettable adventures.
              </Text>
            </Box>
            <Stack direction={"row"} spacing={6}>
              <SocialButton
                label={"Twitter"}
                href={"https://x.com/maggiesafaris"}
              >
                <FaTwitter />
              </SocialButton>
              <SocialButton label={"YouTube"} href={"#"}>
                <FaYoutube />
              </SocialButton>
              <SocialButton
                label={"FaTiktok"}
                href={"https://www.tiktok.com/@maggiesafaris"}
              >
                <FaTiktok />
              </SocialButton>
              <SocialButton
                label={"FaFacebook"}
                href={"https://m.facebook.com/61562038419499"}
              >
                <FaFacebook />
              </SocialButton>
            </Stack>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>Quick Links</ListHeader>
            <Link href={"/About"} color="gray.300">
              About us
            </Link>
            <Link href={"/Itineraries"} color="gray.300">
              Safaris
            </Link>
            <Link href={"/Contact"} color="gray.300">
              Contact us
            </Link>
            <Link href={"/"} color="gray.300">
              Home
            </Link>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>Support</ListHeader>
            <Link href={"#"} color="gray.300">
              Terms of Service
            </Link>
            <Link href={"#"} color="gray.300">
              Privacy Policy
            </Link>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>Stay up to date</ListHeader>
            <Stack
              direction={"row"}
              as="form"
              onSubmit={handleSubscribe}
              ref={formRef}
              name="submit-to-google-sheet"
            >
              <FormControl>
                <Input
                  type={"email"}
                  placeholder={"Your email address"}
                  bg={useColorModeValue("whiteAlpha.100", "whiteAlpha.100")}
                  border={0}
                  _focus={{
                    bg: "whiteAlpha.300",
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                />
              </FormControl>
              <IconButton
                bg={useColorModeValue("green.400", "green.800")}
                color={useColorModeValue("white", "gray.800")}
                _hover={{
                  bg: "green.600",
                }}
                aria-label="Subscribe"
                icon={isSubmitting ? <Spinner size="sm" /> : <BiMailSend />}
                type="submit"
                isLoading={isSubmitting}
                loadingText=""
              />
            </Stack>
          </Stack>
        </SimpleGrid>
      </Container>
      <Box
        borderTopWidth={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.700", "gray.700")}
      >
        <Container
          as={Stack}
          maxW={"6xl"}
          py={4}
          direction={{ base: "column", md: "row" }}
          spacing={4}
          justify={{ base: "center", md: "center" }}
          align={{ base: "center", md: "center" }}
        >
          <Text fontSize={"sm"}>
            © 2024 Maggie Safari Ventures. All rights reserved
          </Text>
        </Container>
      </Box>
    </Box>
  );
}
